import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./EditEpisodemanage.css";
import Dropzone from "react-dropzone";
import cancel_video from "../../../components/Images/icons/cancel_video.svg";
import retry_video from "../../../components/Images/icons/retry_video.svg";
import "jspdf-autotable";

export default function EditEpisodeVideo(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleReset = () => {
    setActiveStep(0);
  };


  const navigate = useNavigate();
  const { id } = useParams();
  const access_token = localStorage.getItem("access_token");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };



  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [progress, setProgress] = useState({});
  const [cancelTokens, setCancelTokens] = useState({});
  const [pausedFiles, setPausedFiles] = useState({});
  const [failedFiles, setFailedFiles] = useState([]);

  const maxRetries = 3;

  const updateProgress = (fileName, percentCompleted) => {
    setProgress(prevProgress => ({
      ...prevProgress,
      [fileName]: percentCompleted,
    }));
  };

  const overallProgress = () => {
    const totalFiles = Object.keys(progress).length;
    const totalProgress = Object.values(progress).reduce((acc, value) => acc + value, 0);
    return totalFiles ? totalProgress / totalFiles : 0;
  };
  const [uploadSpeed, setUploadSpeed] = useState({});
  const [fileSizes, setFileSizes] = useState({});


  const uploadFileWithRetry = async (file, retryCount = 0, start = 0) => {
    const formData = new FormData();
    formData.append('file', file.slice(start), file.name);
    formData?.append("episode_id", id);

    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokens(prevTokens => ({ ...prevTokens, [file.name]: cancelTokenSource }));

    const uploadStartTime = Date.now();
    let previousUploaded = start;

    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/admin/Edit-Episode-Upload`, formData, {
        headers: {
          Authorization: 'Bearer ' + access_token,
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Content-Range': `bytes ${start}-${file.size - 1}/${file.size}`
        },
        onUploadProgress: (progressEvent) => {
          const currentTime = Date.now();
          const timeElapsed = (currentTime - uploadStartTime) / 1000; // Time in seconds

          // Calculate percent completed
          const percentCompleted = Math.round(((progressEvent.loaded + start) * 100) / file.size);
          updateProgress(file.name, percentCompleted);

          // Calculate upload speed
          const uploadedSize = progressEvent.loaded + start - previousUploaded;
          let speed = uploadedSize / timeElapsed; // Bytes per second

          let formattedSpeed;
          if (speed > 1024 * 1024) {
            // MB/sec
            formattedSpeed = `${(speed / (1024 * 1024)).toFixed(2)} MB/sec`;
          } else if (speed > 1024) {
            // KB/sec
            formattedSpeed = `${(speed / 1024).toFixed(2)} KB/sec`;
          } else {
            // Bytes/sec
            formattedSpeed = `${speed.toFixed(2)} B/sec`;
          }

          // Update speed in state for UI display
          setUploadSpeed(prevSpeeds => ({ ...prevSpeeds, [file.name]: formattedSpeed }));
          previousUploaded = progressEvent.loaded + start;
        },
        cancelToken: cancelTokenSource.token
      });

      // Handling successful upload response
      const uploadedDetail = {
        name: file.name,
        size: (file.size / (1024 * 1024)).toFixed(2),
      };

      setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, uploadedDetail]);
      props?.setApiresponsealert(response.data);
      props?.setShowalert(true);

      if (response.data.status === true) {
        props?.setApiresponsealert({ status: true, message: `Successfully uploaded ${file.name}` });
        // navigate('/video-lists')
        window.location.reload();

      } else {
        props?.setApiresponsealert(response.data);
        props?.setShowalert(true);
        updateProgress(file.name, 0);
      }
    } catch (error) {
      // Error handling with retry logic
      if (axios.isCancel(error)) {
        console.log('Upload paused:', file.name);
        setPausedFiles(prevPausedFiles => ({ ...prevPausedFiles, [file.name]: start }));
      } else {
        console.error('Error uploading video:', error);
        if (retryCount < maxRetries) {
          console.log(`Retrying upload for ${file.name} (Attempt ${retryCount + 1})`);
          await uploadFileWithRetry(file, retryCount + 1, start);
        } else {
          props?.setApiresponsealert({ status: false, message: `Failed to upload ${file.name} after ${maxRetries} attempts.` });
          props?.setShowalert(true);
          setFailedFiles(prevFailedFiles => [...prevFailedFiles, file]);
        }
      }
    }
  };


  const handleUpload = async (files) => {
    try {
      setLoading(true);

      const fileSizes = {}; // Initialize an object to store file sizes

      const uploadPromises = files.map((file) => {
        const start = pausedFiles[file.name] || 0;
        fileSizes[file.name] = (file.size / (1024 * 1024)).toFixed(2); // Calculate file size in MB
        return uploadFileWithRetry(file, 0, start);
      });

      setFileSizes(fileSizes); // Update state with file sizes

      await Promise.all(uploadPromises);

      setLoading(false);
      setUploadCompleted(true);
    } catch (error) {
      console.error('Error uploading videos:', error);
      setLoading(false);
    }
  };


  const cancelUpload = (fileName) => {
    if (cancelTokens[fileName]) {
      cancelTokens[fileName].cancel(`Upload canceled for ${fileName}`);
      setCancelTokens(prevTokens => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[fileName];
        return updatedTokens;
      });
    }
  };

  const pauseUpload = (fileName) => {
    if (cancelTokens[fileName]) {
      cancelTokens[fileName].cancel(`Upload paused at ${progress[fileName]}`);
      setCancelTokens(prevTokens => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[fileName];
        return updatedTokens;
      });
    }
  };

  const resumeUpload = (file) => {
    if (pausedFiles[file.name] !== undefined) {
      const start = pausedFiles[file.name];
      const fileToResume = new File([file], file.name, { type: file.type });
      uploadFileWithRetry(fileToResume, 0, start);
      setPausedFiles(prevPausedFiles => {
        const updatedPausedFiles = { ...prevPausedFiles };
        delete updatedPausedFiles[file.name];
        return updatedPausedFiles;
      });
    }
  };

  const retryUpload = (file) => {
    setFailedFiles(prevFailedFiles => prevFailedFiles.filter(f => f.name !== file.name));
    handleUpload([file]);
  };


  const datadata = [
    {
      value: "add_video",
      label: "Episode Upload",
      content: (
        <>
          <div>
            <Dropzone accept="video/*" onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps({ accept: 'video/*' })} id="add_video_upload" />
                  <p className="draganddrop">Drag and drop video files here, or click to select files</p>
                </div>
              )}
            </Dropzone>

            <div className="text-center video-overall-progress-li">
              {loading && (
                <div>
                  <p>Uploading... {overallProgress().toFixed(2)}%</p>
                  <progress value={overallProgress()} max="100" />
                  <div className="upload-progress-list d-flex flex-wrap">
                    {Object.keys(progress)?.map((fileName, index) => (
                      <div key={index} className="upload-progress-item d-flex flex-column align-items-center">
                        <div className="file-info d-flex align-items-center justify-content-center">
                          <div>
                            <p className="file-name mb-0">{fileName.substring(0, 10)}...</p>
                            <p className="file-size mb-0">{fileSizes[fileName]} MB</p>
                          </div>
                        </div>

                        <div className="progress-bar-container">
                          <progress value={progress[fileName]} max="100" className="file-progress-bar" />
                          <span className="progress-percentage ">{progress[fileName]}%</span>
                        </div>

                        {uploadSpeed[fileName] && <span className="file-uploadSpeed">({uploadSpeed[fileName]})</span>}
                        <img
                          onClick={() => cancelUpload(fileName)}
                          src={cancel_video}
                          className="cancel-icon"
                          alt="Cancel"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {uploadedFiles?.length > 0 && !loading && (
                <div className="uploaded-files-section">
                  <p>Uploaded Files:</p>
                  <ul>
                    {uploadedFiles?.map((file, index) => (
                      <li key={index} className="uploaded-file-item">
                        <span>{file?.name} - {file?.size} MB</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {failedFiles?.length > 0 && (
                <div className="failed-files-section">
                  <p>Failed Files:</p>
                  <ul>
                    {failedFiles?.map((file, index) => (
                      <li key={index} className="failed-file-item d-flex align-items-center justify-content-between">
                        <span>{file?.name}</span>
                        <img
                          onClick={() => retryUpload(file)}
                          src={retry_video}
                          className="retry-icon"
                          alt="Retry"
                          style={{ cursor: "pointer" }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

        </>
      ),
    },
  ]

  const [selectedOption, setSelectedOption] = useState(datadata[0].value);

  const steps = [
    {
      label: "Add New Video",
      description: (
        <div>
          <div className="mt-3">
            <h4>Update Episode</h4>
          </div>
          <div className="card mt-4 p-3">

            <div className="active-div-content">
              {
                datadata?.find((option) => option?.value === selectedOption)
                  ?.content
              }
            </div>
          </div>
        </div>
      ),
    },

  ];

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid ">
          <div className="">
            <div className="row">
              <div className="col-sm-12">
                <div className="addvideo">
                  <Box>
                    <Stepper
                      className="video"
                      activeStep={activeStep}
                      nonLinear
                      orientation="vertical"
                    >
                      {steps?.map((step, index) => (
                        <Step key={step.label}>
                          <StepContent>
                            <Typography>{step?.description}</Typography>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>

                    {activeStep === steps?.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
