import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import { TagsInput } from "react-tag-input-component";
import "./AddNewLiveStream.css";
import "./EditAddNewLiveStream.css";
import Select from "react-select";
import { Modal, Form, Table, Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import Response_Loading_processing from "../../../../src/layout/Others/Response_Loading_processing";
import facebook from "../../../components/Images/liveicon/facebook.png";
import twitter from "../../../components/Images/liveicon/twitter.png";
import youtube from "../../../components/Images/liveicon/youtube.png";
import ReactPlayer from "react-player";
import useCompressImageConfig from "../../../components/CommonApis/Compressimageapi";
import usePpvGlobalImageConfig from "../../../components/CommonApis/ppv_global_change";

import NavigationData from "../../../layout/Sidebar/NavigationData";
import AdminCustomURLCopy from "../../../components/CommonComponents/AdminCustomURLCopy";
function EditAddNewLiveStream(props) {
  const { label_management } = NavigationData();
  const { id } = useParams();
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    enable_restream: "",
    Stream_key: "",
    TV_image: "",
    player_image: "",
    access: "",
    active: "",
    banner: "",
    live_stream_source: "",
    details: "",
    embed_url: "",
    fb_restream_url: "",
    fb_streamkey: "",
    featured: "",
    footer: "",
    youtube_streamkey: "",
    youtube_restream_url: "",
    year: "",
    video_category_id: "",
    url_type: "",
    twitter_streamkey: "",
    twitter_restream_url: "",
    status: "",
    rating: "",
    publish_type: "",
    ppv_price: "",
    mp4_url: "",
    m3u_url: "",
    live_stream_video: "",
    linkedin_streamkey: "",
    linkedin_restream_url: "",
    language: "",
    ios_ppv_price: "",
    free_duration_time: "",
    free_duration_status: "",
    image: "",
    hls_url: "",
    description: "",
    duration: "",
    search_tags: "",
    Video_thumbnail: "",
    Player_thumbnail: "",
    Video_TV_Thumbnail: "",
    enable_Tips: "",
    enable_chats: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    livestream_URL: "",
    donations_label: "",
    rtmp_url: "",
  });
  const [editname, setEditname] = useState({
    title: "",
  });

  // console.log(editUser)

  // const [message, setMessage] = useState("");
  const access_token = localStorage.getItem("access_token");
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);

  // const [publish_type, setPublish_type] = useState("");
  // const [publish_time, setPublish_time] = useState("");

  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  // const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  // console.log(age_restrictdata)
  // const [age_restrict, setAge_restrict] = useState([]);
  // const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  // const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  // const [enable_restream, setEnable_restream] = useState("");
  // const [ppv_price, setPpv_price] = useState("");
  // const [ios_ppv_price, setIos_ppv_price] = useState("");
  // const [fb_restream_url, setFb_restream_url] = useState("");
  // const [youtube_restream_url, setYoutube_restream_url] = useState("");
  // const [twitter_restream_url, setTwitter_restream_url] = useState("");
  // const [fb_streamkey, setFb_streamkey] = useState("");
  // const [youtube_streamkey, setYoutube_streamkey] = useState("");
  // const [twitter_streamkey, setTwitter_streamkey] = useState("");
  const [live_stream_video, setLive_stream_video] = useState("");
  // const [showModalshowModal, setshowModalshowModal] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  // const [showInputs, setShowInputs] = useState(false);
  // const [featured, setFeatured] = useState(0);
  // const [category, setCategory] = useState([]);
  // const [cate, setCate] = useState([]);
  // const [artist, setArtists] = useState([]);
  // const [language, setLanguage] = useState([]);

  // console.log(access_token)

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: inputValueAPI };
      });
    } else {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: "" };
      });
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value; // setInputValue(event.target.value);
    setEditUser((prevState) => {
      return { ...prevState, [name]: value };
    });

    // alert(event.target.value);
  };

  // const handleCheckbox = () => {
  //   setEnable_restream(!enable_restream);
  //   if (!enable_restream) {
  //     setshowModalshowModal(true);
  //   } else {
  //     setShowInputs(false);
  //     setSelectedButtons([]);
  //   }
  // };

  // const [enableRestream, setEnableRestream] = useState(false);
  const [showModalshowModal, setshowModalshowModal] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([
    "Twitter",
    "YouTube",
    "Facebook",
  ]);
  const [showInputs, setShowInputs] = useState(false);
  // const [enableRestreamtoggle, setEnableRestreamtoggle] = useState("");

  const handleButtonClicktoggle = (platform) => {
    setSelectedPlatforms((prevPlatforms) => {
      if (prevPlatforms.includes(platform)) {
        return prevPlatforms.filter((p) => p !== platform);
      } else {
        return [...prevPlatforms, platform];
      }
    });
  };

  const handleCloseModal = () => {
    setshowModalshowModal(false);
  };

  const handleSave = () => {
    setShowInputs(true);
    setshowModalshowModal(false);
  };

  const handleCheckbox = () => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      enable_restream: prevEditUser?.enable_restream === 1 ? 0 : 1,
    }));
  };

  // const handleChangeyear = (event) => {
  //   const value = event.target.value;
  //   if (/^\d{0,4}$/.test(value)) {
  //     setEditUser({ ...editUser, year: value });
  //   }
  // };
  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, description: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirstdetails = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirsttraildetails = (e) => {
    setEditUser({ ...editUser, trailer_description: e });
    // console.log(e, "onchnageddddd");
  };

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue === "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit.set("publish_time", timeValue);
    formDatapublishedit.set("publish_type", publishType);
  };



  const [ppv_gobal_price, setppv_gobal_price] = useState();
  const [allCurrencyglobal, setAllCurrencyglobal] = useState([]);
  const [allCurrencycustoms, setAllCurrencycustoms] = useState([]);
  const [allCurrencylistfromappichoosed, setAllCurrencylistfromappichoosed] = useState();
  const { allCurrencylistfromappi, globalPpvStatusActive } = usePpvGlobalImageConfig();
  const global_ppv_status_active = globalPpvStatusActive

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  // const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/edit/${id}`,
        { headers: headers }
      );
      var res = response?.data?.Live_Stream[0];
      const data = response?.data?.Live_Stream[0]?.selected_Blocklivestream;
      const dataavailable_country =
        response?.data?.Live_Stream[0]?.available_countries;
      const dataartists = response?.data?.Live_Stream[0]?.selected_artist;
      const datacategories = response?.data?.Live_Stream[0]?.selected_category;
      const datalanguages = response?.data?.Live_Stream[0]?.selected_language;
      const dataAge_Restrict =
        response?.data?.Live_Stream[0]?.selected_Age_Restrict;
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      setEditUser(res);
      setPublishType(res?.publish_type);
      setPublishTime(res?.publish_time);
      var globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);


      //ppv
      var Defaultppv_gobal_price = response?.data?.ppv_gobal_price;
      setppv_gobal_price(Defaultppv_gobal_price)
      var DefaultCurrencytest = response?.data?.DefaultCurrency[0];
      setAllCurrencylistfromappichoosed(DefaultCurrencytest);
      var DefaultGlobalContentPriceRates = response?.data?.GlobalContentPriceRates;
      setAllCurrencyglobal(DefaultGlobalContentPriceRates);
      var DefaultContentPriceRates = response?.data?.ContentPriceRates;
      setAllCurrencycustoms(DefaultContentPriceRates);

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.artist_id,
        label: item?.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.category_id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.language_id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      // const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      // setSelectedValuesrelated_video(formattedOptionsrelated_video);
      // setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);

      setLoading(false);
      setTags(
        response?.data?.Live_Stream[0]?.search_tags
          .split(",")
          .map((tag) => tag.trim())
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.livestream_artist;
      const datacategories = response?.data?.livestream_category;
      const datalanguages = response?.data?.livestream_language;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      // setSelectedLanguages(data)
      // console.log(data);
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setLivestream_source(response?.data?.Livestream_source);
      // console.log(data);
      // console.log(data);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.id,
        label: item?.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.id,
      }));
      // const formattedOptionsrelated_video = datarelated_video.map((item) => ({
      //   value: item?.id,
      //   label: item?.id,
      // }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      block_country: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      available_country: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      categories: categories,
    });

    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      related_video: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option?.value);

    setFormData({
      ...formData,
      // ...editInputvalue,
      languages: languages,
    });
    setIsValidationHiddenlanguages(true);

    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option?.value
    );

    setFormData({
      ...formData,
      // ...editInputvalue,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW


  const { compressResolutionFormat } = useCompressImageConfig();

  const [error, setError] = useState({
    file1: '',
    file2: '',
    file3: '',
  });

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  // const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const validateImageRatio = (file, expectedRatio, expectedWidth, expectedHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const ratio = width / height;
        const expectedRatioPrecision = 0.01; // Allow for some minor floating-point precision issues
        const ratioMatch = Math.abs(ratio - expectedRatio) < expectedRatioPrecision;
        const dimensionMatch = (width === expectedWidth && height === expectedHeight);
        if (ratioMatch || dimensionMatch) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      img.onerror = () => {
        reject(new Error('Error loading image'));
      };
    });
  };

  const handleFileChange = async (event, fileInputRef, setSelectedFile, errorKey) => {
    const file = event.target.files[0];
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));

    if (compressResolutionFormat?.live === 1) {
      let isValid = false;

      if (fileInputRef === fileInputRef1) {
        isValid = await validateImageRatio(file, 9 / 16, 1080, 1920);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file1: 'Image must be in 9:16 Ratio or 1080x1920px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef2) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file2: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      } else if (fileInputRef === fileInputRef3) {
        isValid = await validateImageRatio(file, 16 / 9, 1280, 720);
        if (!isValid) {
          setError(prevErrors => ({ ...prevErrors, file3: 'Image must be in 16:9 Ratio or 1280x720px.' }));
          fileInputRef.current.value = null;
          return;
        }
      }
    }

    setSelectedFile(file);
    setError(prevErrors => ({ ...prevErrors, [errorKey]: '' }));
  };


  const handleDelete = (index) => {
    setError('');
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  const handleButtonClick = (button) => {
    setSelectedButtons((prevSelectedButtons) => [
      ...prevSelectedButtons,
      button,
    ]);
  };

  // const handleCloseModal = () => {
  //   setshowModalshowModal(false);
  // };

  // const handleSave = () => {
  //   setshowModalshowModal(false);
  //   setShowInputs(true);
  // };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  useEffect(async () => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.Live_Stream[0];
      setEditUser(res);
      setEditname(res);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setallliveuseraccess(value);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    // if (e.target.checked == true) {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 1 });
    //   setEditUser({
    //     ...editUser,
    //     [e.target.name]: e.target.value,
    //     [e.target.name]: 1,
    //   });
    // } else {
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    //   setEditUser({ ...editUser, [e.target.id]: 0 });
    //   setEditUser({ ...editUser, [e.target.name]: e.target.value });
    // }
  };

  const handleInputenable = (e) => {
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formattedDatatype = convertToTitleCase(editUser?.url_type);

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const accessInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const slugInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (editUser?.slug === null || editUser?.slug === "") {
      setValidationMessageslug("Slug cannot be empty.");
      setIsValidationHiddenslug(false);
      focusInputRef = slugInputRef;
    }

    if (!selectedValuescategories || selectedValuescategories?.length === 0) {
      setValidationMessagecategory("Please select at least one Category.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }

    if (editUser?.access === "" || editUser?.access === null) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }
    // if (!selectedValueslanguages || selectedValueslanguages?.length === 0) {
    //   setValidationMessagelanguages("Please select at least one Language.");
    //   setIsValidationHiddenlanguages(false);
    //   focusInputRef = languageInputRef;
    // }

    let valid = true;

    if (compressResolutionFormat?.live === 1) {
      if (!selectedFile1 && !editUser?.Video_thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file1: 'Image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile2 && !editUser?.Player_thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file2: 'Player image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

      if (!selectedFile3 && !editUser?.Video_TV_Thumbnail) {
        setError(prevErrors => ({ ...prevErrors, file3: 'Video TV image is required.' }));
        valid = false;
      } else {
        setError(prevErrors => ({ ...prevErrors, file1: '' }));
      }

    }

    if (!valid) {
      return;
    }

    if (focusInputRef) {
      focusInputRef?.current?.focus();
      return;
    }
    const updatedTagsString = tags?.join(", ");

    const editInputvalue = {
      fb_streamkey: editUser?.fb_streamkey,
      title: editUser?.title,
      live_id: id,
      slug: editUser?.slug,
      url_type: editUser?.url_type,
      hls_url: editUser?.hls_url,
      rtmp_url: editUser?.rtmp_url,
      mp4_url: editUser?.mp4_url,
      player_image: editUser?.player_image,
      enable_restream: editUser?.enable_restream,
      Stream_key: editUser?.Stream_key,
      Tv_live_image: editUser?.Tv_live_image,
      youtube_streamkey: editUser?.youtube_streamkey,
      video_category_id: editUser?.video_category_id,
      twitter_streamkey: editUser?.twitter_streamkey,
      twitter_restream_url: editUser?.twitter_restream_url,
      youtube_restream_url: editUser?.youtube_restream_url,
      status: editUser?.status,
      rating: editUser?.rating,
      search_tags: updatedTagsString,
      publish_type: publishType,
      publish_time: publishTime,
      free_duration_status: editUser?.free_duration_status,
      free_duration_time: editUser?.free_duration_time,
      year: editUser?.year,
      description: editUser?.description,
      access: editUser?.access,
      ppv_price: editUser?.ppv_price,
      global_ppv: checkboxChecked === true ? 1 : 0 || editUser?.global_ppv,
      footer: editUser?.footer,
      duration: editUser?.duration,
      active: editUser?.active,
      enable_chats: editUser?.enable_chats,
      enable_Tips: editUser?.enable_Tips,
      banner: editUser?.banner,
      details: editUser?.details,
      embed_url: editUser?.embed_url,
      ads_status: editUser?.ads_status,
      default_ads: editUser?.default_ads,
      fb_restream_url: editUser?.fb_restream_url,
      featured: editUser?.featured,
      m3u_url: editUser?.m3u_url,
      // live_stream_video: live_stream_video,
      linkedin_streamkey: editUser?.linkedin_streamkey,
      linkedin_restream_url: editUser?.linkedin_restream_url,
      language: editUser?.language,
      image: editUser?.image,
      ios_ppv_price: editUser?.ios_ppv_price,
      pre_ads_category: editUser?.pre_ads_category,
      mid_ads_category: editUser?.mid_ads_category,
      post_ads_category: editUser?.post_ads_category,
      pre_ads: editUser?.pre_ads,
      mid_ads: editUser?.mid_ads,
      post_ads: editUser?.post_ads,
      ads_tag_url_id: editUser?.ads_tag_url_id,
      age_restrict: editUser?.age_restrict,
      donations_label: editUser?.donations_label,
      languages_id: selectedValueslanguages?.map((option) => option?.value),
      artist_id: selectedValuesartists?.map((option) => option?.value),
      block_country_id: selectedValues?.map((option) => option?.value),
      category_id: selectedValuescategories?.map((option) => option?.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option?.value
      ),
      ...formData,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let sixthApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/admin/live-stream/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        var livestream_id = resjson?.LiveStream?.id;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("livestream_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("TV_image", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/Image-upload`,
          formData,
          { headers: headers }
        );

        const formDavideo = new FormData();

        formDavideo.append("livestream_id", livestream_id);
        formDavideo.append("live_stream_video", live_stream_video);

        const formDavideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/video-update`,
          formDavideo,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("livestream_id", livestream_id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
        if (formDavideoresponse?.data?.status === true) {
          sixthApiStatus = true;
        }
        if (formDataseoresponse?.data?.status === true) {
          seventhApiStatus = true;
        }
        // secondApiStatus = true;
      } else if (resjson?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (
      firstApiStatus &&
      secondApiStatus &&
      sixthApiStatus &&
      seventhApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (
      firstApiStatus &&
      secondApiStatus &&
      sixthApiStatus &&
      seventhApiStatus
    ) {
      props?.setApiresponsealert(resultapifalse);
      props?.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  // const handleUpdate = async (e) => {
  //   e.preventDefault();

  //   if (editUser?.title === "") {
  //     setValidationMessagetitle("Title cannot be empty.");
  //     setIsValidationHiddentitle(false);
  //     return;
  //   }

  //   if (editUser?.slug === "") {
  //     setValidationMessageslug("Slug cannot be empty.");
  //     setIsValidationHiddenslug(false);
  //     return;
  //   }

  //   if (!selectedValuescategories || selectedValuescategories.length === 0) {
  //     setValidationMessagecategory("Please select at least one Category.");
  //     setIsValidationHiddencategory(false);
  //     return;
  //   }

  //   if (!selectedValueslanguages || selectedValueslanguages.length === 0) {
  //     setValidationMessagelanguages("Please select at least one Language.");
  //     setIsValidationHiddenlanguages(false);
  //     return;
  //   }

  //   const updatedTagsString = tags.join(", ");

  //   const editInputvalue = {
  //     fb_streamkey: editUser?.fb_streamkey,
  //     title: editUser?.title,
  //     slug: editUser?.slug,
  //     url_type: editUser?.url_type,
  //     mp4_url: editUser?.mp4_url,
  //     player_image: editUser?.player_image,
  //     enable_restream: editUser?.enable_restream,
  //     Stream_key: editUser?.Stream_key,
  //     Tv_live_image: editUser?.Tv_live_image,
  //     youtube_streamkey: editUser?.youtube_streamkey,
  //     video_category_id: editUser?.video_category_id,
  //     twitter_streamkey: editUser?.twitter_streamkey,
  //     twitter_restream_url: editUser?.twitter_restream_url,
  //     url_type: editUser?.url_type,
  //     youtube_restream_url: editUser?.youtube_restream_url,
  //     status: editUser?.status,
  //     rating: editUser?.rating,
  //     search_tags: updatedTagsString,
  //     publish_type: publishType,
  //     publish_time: publishTime,
  //     year: editUser?.year,
  //     description: editUser?.description,
  //     access: editUser?.access,
  //     ppv_price: inputValue,
  //     footer: editUser?.footer,
  //     mp4_url: editUser?.mp4_url,
  //     duration: editUser?.duration,
  //     active: editUser?.active,
  //     enable_chats: editUser?.enable_chats,
  //     enable_Tips: editUser?.enable_Tips,
  //     banner: editUser?.banner,
  //     details: editUser?.details,
  //     embed_url: editUser?.embed_url,
  //     ads_status: editUser?.ads_status,
  //     default_ads: editUser?.default_ads,
  //     fb_restream_url: editUser?.fb_restream_url,
  //     featured: editUser?.featured,
  //     m3u_url: editUser?.m3u_url,
  //     // live_stream_video: live_stream_video,
  //     linkedin_streamkey: editUser?.linkedin_streamkey,
  //     linkedin_restream_url: editUser?.linkedin_restream_url,
  //     language: editUser?.language,
  //     image: editUser?.image,
  //     ios_ppv_price: editUser?.ios_ppv_price,
  //     hls_url: editUser?.hls_url,
  //     pre_ads_category: editUser?.pre_ads_category,
  //     mid_ads_category: editUser?.mid_ads_category,
  //     post_ads_category: editUser?.post_ads_category,
  //     pre_ads: editUser?.pre_ads,
  //     mid_ads: editUser?.mid_ads,
  //     post_ads: editUser?.post_ads,
  //     ads_tag_url_id: editUser?.ads_tag_url_id,
  //     age_restrict: editUser?.age_restrict,
  //     languages_id: selectedValueslanguages.map((option) => option.value),
  //     artist_id: selectedValuesartists.map((option) => option.value),
  //     block_country_id: selectedValues.map((option) => option.value),
  //     category_id: selectedValuescategories.map((option) => option.value),
  //     available_countries_id: selectedValuesavailable_country.map(
  //       (option) => option.value
  //     ),
  //     ...formData,
  //   };
  //   // console.log(editInputvalue);
  //   let res = await fetch(
  //     `${process.env.REACT_APP_Baseurl}/admin/live-stream/update/` + id,
  //     {
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify(editInputvalue),
  //     }
  //   );

  //   let resjson = await res.json();
  //   // console.log(resjson)
  //   if (resjson.status === true) {
  //     // setMessage(resjson.success);
  //     // setTimeout(() => {
  //     //   navigate("/livestream");
  //     // }, 2000);
  //     // console.log(resjson)

  //     var livestream_id = resjson.LiveStream.id;
  //     var apilivestream = resjson;
  //     // console.log(livestream_id)
  //     // console.log("livestream_id", livestream_id);

  //     const formDataseo = new FormData();
  //     formDataseo.append("livestream_id", livestream_id);
  //     formDataseo.append("website_page_title", editUser?.website_page_title);
  //     formDataseo.append("website_URL", editUser?.website_URL);
  //     formDataseo.append("Meta_description", editUser?.Meta_description);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/live-stream/SEO`,
  //         formDataseo,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         console.log("File(s) uploaded successfully:", response.data);
  //       });

  //     const formDatavideo = new FormData();
  //     formDatavideo.append("livestream_id", livestream_id);
  //     formDatavideo.append("live_stream_video", live_stream_video);

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_Baseurl}/admin/live-stream/video-update`,
  //         formDatavideo,
  //         { headers: headers }
  //       )
  //       .then((response) => {
  //         console.log("File(s) uploaded successfully:", response.data);
  //       });

  //     const formData = new FormData();
  //     formData.append("livestream_id", livestream_id);
  //     if (selectedFile1) {
  //       formData.append("image", selectedFile1);
  //     }
  //     if (selectedFile2) {
  //       formData.append("player_image", selectedFile2);
  //     }
  //     if (selectedFile3) {
  //       formData.append("TV_image", selectedFile3);
  //     }

  //     {
  //       axios
  //         .post(
  //           `${process.env.REACT_APP_Baseurl}/admin/live-stream/Image-upload`,
  //           formData,
  //           { headers: headers }
  //         )
  //         .then((response) => {
  //           // console.log("File(s) uploaded successfully:", response.data);
  //           // Perform any actions with the response data as needed.
  //           // navigate("/livestream");
  //           // alert("Added successfully  !");
  //         })
  //         .catch((error) => {
  //           console.error("Error uploading file(s):", error);
  //           // Handle any errors that occurred during the upload process.
  //         });
  //     }

  //     setValidationMessagetitle("");
  //     setValidationMessageslug("");

  //     setValidationMessagecategory("");
  //     setValidationMessagelanguages("");

  //     props?.setApiresponsealert(apilivestream);
  //     props?.setShowalert(true);

  //     setTimeout(() => {
  //       // window.location.reload();
  //     }, 3000);
  //   } else {
  //     if (resjson.status === false) {
  //       var resultError = resjson;
  //       props?.setApiresponsealert(resultError);
  //       props?.setShowalert(true);
  //       // console.log(resultError)

  //       setIsValidationHiddentitle(false);
  //       setValidationMessageslug(false);

  //       setIsValidationHiddencategory(false);
  //       setIsValidationHiddenlanguages(false);
  //     }
  //   }
  // };

  useEffect(async () => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/admin/live-stream/create`,
          {
            headers: headers,
          }
        );
        const data = await response?.json();
        const options = data?.livestream_category?.map((category) => ({
          value: category?.id,
          label: category?.name,
        }));
        setAllOptions(options);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  const [alllivestream_source, setalllivestream_source] = useState("");
  // const [storelive_source, setStorelive_source] = useState("");

  function alllive_stream_source() {
    switch (alllivestream_source) {
      case "0":
        return <div></div>;
      case "mp4":
        return (
          <div className="mt-2">
            <label>Mp4/M3U8 URL</label>
            <input
              type="text"
              className="rs-input mt-2 form-control-lg"
              onChange={handleInput}
              value={editUser?.mp4_url}
              placeholder=""
              name="mp4_url"
              id="mp4_url"
            />
          </div>
        );
      case "embed":
        return (
          <div className="mt-2">
            <label>Embed Url</label>

            <input
              type="text"
              name="embed_url"
              id="embed_url"
              onChange={handleInput}
              className="rs-input mt-2 form-control-lg"
              value={editUser?.embed_url}
              placeholder=""
            />
          </div>
        );
      case "live_stream_video":
        return (
          <div className="mt-2">
            <label>Live Stream Video</label>

            <div>
              {loading ? (
                <Response_Loading_processing></Response_Loading_processing>
              ) : (
                <media-player
                  // title={editUser?.Video_reels_video}
                  src={editUser?.livestream_URL}
                  poster=""
                  controls
                >
                  <media-outlet></media-outlet>
                </media-player>
              )}
            </div>

            <input
              type="file"
              accept="video/mp4"
              className="rs-input mt-2 form-control-lg"
              onChange={(e) => setLive_stream_video(e.target.files[0])}
              placeholder=""
            />
          </div>
        );
      case "m3u_url":
        return (
          <div className="mt-2">
            <label>M3u url</label>

            <input
              type="text"
              name="m3u_url"
              id="m3u_url"
              onChange={handleInput}
              className="rs-input mt-2 form-control-lg"
              value={editUser?.m3u_url}
              placeholder=""
            />
          </div>
        );

      case "Encode_stream_video":
        return (
          <div className="mt-2">
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  // const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <>
            {global_ppv_status_active?.PPV_Individual_Contents === "0" || global_ppv_status_active?.PPV_Individual_Contents === 0 ? (
              <div className="row mt-3 text-start ">
                <div className="col-sm-12">
                  <label className="m-0">{label_management?.livestream[66]?.title}</label>
                  <div className="col-lg-12 row">
                    <div className="col-8 col-lg-8">
                      <p className="p1">{label_management?.livestream[66]?.sub_title}</p>
                    </div>
                    <div className="col-4 col-lg-4">
                      <label className="switch">
                        <input
                          name="ppv_price"
                          defaultChecked={checkboxChecked}
                          onChange={handleCheckboxChange}
                          type="checkbox"
                          className="rs-input"
                        />
                        <span
                          className="slider round"
                          name="ppv_price"
                          checked={checkboxChecked}
                          onChange={handleCheckboxChange}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="panel-body">
                    <input
                      type="number"
                      className="rs-input"
                      placeholder="PPV Price"
                      value={editUser?.ppv_price}
                      id="ppv_price"
                      name="ppv_price"
                      // value={inputValue}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <label className="m-0">{label_management?.livestream[62]?.title}</label>
                  <p className="p1">{label_management?.livestream[62]?.sub_title}</p>
                  <div className="panel-body ppv_price_ios mt-2">
                    <select
                      // onChange={(e) => setIos_ppv_price(e.target.value)}
                      name="ios_ppv_price"
                      id="ios_ppv_price"
                      onChange={handleInput}
                      value={editUser?.ios_ppv_price}
                      className="form-select"
                    >
                      {inapppurchase?.map((item, key) => (
                        <option value={item?.product_id}>{item?.plan_price}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

            ) :
              <div className="mt-2">
                <div className="col-sm-12">
                  <div className='modalofglobalppv'>
                    <Form>
                      <>
                        {global_ppv_status_active?.ppv_status === "1" || global_ppv_status_active?.ppv_status === 1 ? (
                          <Form.Check
                            type="radio"
                            label={label_management?.livestream[58]?.title}
                            name="priceOption"
                            value="1"
                            checked={editUser?.global_ppv === "1" || editUser?.global_ppv === 1}
                            // onChange={handleChange}
                            onClick={() => handleChange({ target: { value: '1' } })}
                          />
                        ) : null}
                      </>
                      <Form.Check
                        type="radio"
                        label={label_management?.livestream[63]?.title}
                        name="priceOption"
                        value="2"
                        checked={editUser?.global_ppv === null || (editUser?.global_ppv !== "1" && editUser?.global_ppv !== 1)}
                        // onChange={handleChange}
                        onClick={() => handleChange({ target: { value: '2' } })}
                      />
                    </Form>
                    <Modal show={showModal} onHide={handleClose} dialogClassName="modal-lg modal-dialog-scrollable" backdrop="static"
                      keyboard={false}>
                      <Modal.Header closeButton>
                        <Modal.Title>{modalType === '1' ? `${label_management?.livestream[59]?.title}` : `${label_management?.livestream[64]?.title}`}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modal-body-custom">
                        {step === 1 ? (
                          <Form>
                            <Form.Group>
                              <Form.Label
                                className="mandatorySimple">{modalType === '1' ? `${label_management?.livestream[60]?.title}` : `${label_management?.livestream[65]?.title}`}</Form.Label>
                              <Form.Control
                                type="number"
                                name="price"
                                value={modalType === '1' ? formDataglobalprice.price : formDatacustomprice.price}
                                onChange={(e) => handlePriceChange(e, modalType === '1' ? 'global' : 'custom')}
                                disabled={modalType === '1'}
                              />
                            </Form.Group>
                            {modalType === '2' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.livestream[61]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {modalType === '1' && (
                              <Form.Group>
                                <Form.Label
                                  className="mandatorySimple">{label_management?.livestream[61]?.title}</Form.Label>
                                <Form.Control
                                  as="select"
                                  name="priceType"
                                  value={formDatacustomprice.priceType}
                                  onChange={handleCustomPriceTypeChange}
                                  disabled
                                >
                                  <option value="">Select Countries</option>
                                  {allCurrencylistfromappi?.map(item => (
                                    <option key={item?.code} value={item?.code}>{item?.country}</option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {/* <div className='text-end mt-2'>
<Button variant="primary" onClick={handleNext}>
Next
</Button>
</div> */}

                            <div className="col-sm-12 mt-2">
                              <label className="m-0">{label_management?.livestream[62]?.title}</label>
                              <p className="p1">{label_management?.livestream[62]?.sub_title}</p>
                              <div className="panel-body ppv_price_ios mt-2">
                                <select
                                  name="ios_ppv_price"
                                  id="ios_ppv_price"
                                  onChange={handleInput}
                                  value={editUser?.ios_ppv_price}
                                  className="form-select"
                                >
                                  <option value="">Select IOS Price</option>
                                  {inapppurchase?.map((item, key) => (
                                    <option value={item?.product_id}>{item?.plan_price}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                          </Form>
                        ) : (
                          <>
                            <div className="table-responsive">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Country</th>
                                    <th>Current Price</th>
                                    <th>Converted Price</th>
                                    <th>Difference</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices).map((item, index) => (
                                    <tr key={index}>
                                      <td>{item?.country_name || item?.Country} ({item?.Code})</td>
                                      <td>{item?.Symbol || item?.symbol} {item?.price || item?.ActualPrice}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          value={item?.editPrice !== undefined ? item?.editPrice : item?.convertedPrice}
                                          onChange={(e) => handleInputChangeeditable(index, 'editPrice', e.target.value)}
                                          disabled={modalType === '1'}
                                        />
                                      </td>
                                      <td>{item?.difference || item?.RateDifference}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            {/* <div className='text-end mt-2'>
<Button variant="secondary" onClick={() => setStep(1)}>
Previous
</Button>
<Button variant="primary" onClick={handleSubmit}>
Submit
</Button>
</div> */}
                          </>
                        )}
                      </Modal.Body>

                      <hr></hr>
                      {step === 1 ? (

                        <div className='text-end p-2'>
                          <Button variant="primary" onClick={handleNext}>
                            Next
                          </Button>
                        </div>
                      ) : (
                        <div className='text-end p-2'>
                          <Button variant="secondary" onClick={() => setStep(1)}>
                            Previous
                          </Button>
                          <Button variant="primary" onClick={handleSubmit} className='ms-2'>
                            Submit
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            }
          </>
        );
      default:
        return null;
    }
  }


  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [step, setStep] = useState(1);
  const [formDataglobalprice, setFormDataglobalprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for global price
    price: ppv_gobal_price,
    convertedPrices: []
  });
  const [formDatacustomprice, setFormDatacustomprice] = useState({
    priceType: allCurrencylistfromappichoosed?.code,  // Default value for custom price
    price: editUser?.ppv_price,
    convertedPrices: []
  });



  const handleChange = (e) => {
    const { value } = e.target;
    if (value === "1") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: "1",
      }));
    } else if (value === "2") {
      setEditUser((prevState) => ({
        ...prevState,
        global_ppv: null,
      }));
    }

    setModalType(e.target.value);
    setShowModal(true);
    setStep(1);
  };

  const handleClose = () => {
    setShowModal(false);
    setFormDataglobalprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: ppv_gobal_price,
      convertedPrices: []
    });
    setFormDatacustomprice({
      priceType: allCurrencylistfromappichoosed?.code,
      price: editUser?.ppv_price,
      convertedPrices: []
    });
  };

  const handleNext = async () => {
    if (step === 1) {
      const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

      if (!formData?.priceType || !formData?.price) {
        toast.error('Please fill in the required fields.')
        return;
      }

      try {
        if (modalType === '2' && formData?.price !== editUser?.ppv_price) {
          const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${formData?.priceType}`);
          const rates = response?.data.rates;

          const convertedPrices = allCurrencylistfromappi?.map(item => {
            let country_name = item?.country;
            let Symbol = item?.Symbol || item?.symbol;
            let Code = item?.code;

            const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
            if (matchingCountry) {
              country_name = matchingCountry?.country;
              Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
            }

            return {
              Code: Code,
              country_name: country_name,
              Symbol: Symbol,
              convertedPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              price: formData?.price,
              difference: ((formData?.price * (rates[item?.code] || 0)) - formData?.price).toFixed(2),
              editPrice: (formData?.price * (rates[item?.code] || 0)).toFixed(2),
              isEditable: true,
            };
          });

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: convertedPrices
          }));
        } else if (modalType === '2' && formData?.price === editUser?.ppv_price) {
          const customPrices = allCurrencycustoms?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            difference: item?.RateDifference || 0,
            isEditable: true
          }));

          setFormDatacustomprice(prevState => ({
            ...prevState,
            convertedPrices: customPrices
          }));
        } else if (modalType === '1') {
          const globalPrices = allCurrencyglobal?.map(item => ({
            ...item,
            convertedPrice: item?.ChangedPrice || (formData?.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
            isEditable: false
          }));
          setFormDataglobalprice(prevState => ({
            ...prevState,
            convertedPrices: globalPrices
          }));
        }

        setStep(2);
      } catch (error) {
        console.error('Error converting currency:', error);
      }
    }
  };


  const handleSubmit = async () => {
    const formData = modalType === '1' ? formDataglobalprice : formDatacustomprice;

    if (!formData || formData?.convertedPrices.length === 0) {
      alert('Please fill in all the fields.');
      return;
    }

    // Prepare data to submit
    const dataToSubmit = {
      source_type: 2, // Note - 1 (Videos) or 2 (Livestream) or 3 (Series) or 4 (Episode) or 5 (Audios) or 6 season or 7 golbal
      source_id: id,
      PPVType: modalType,
      PriceType: formData?.priceType, // Separate priceType outside of convertedPrices
      IOSPrice: editUser?.ios_ppv_price,
      ActualPrice: formData?.price,
      convertedPriceRates: formData?.convertedPrices.map(item => ({
        Code: item?.Code,
        Country: item?.country_name || item?.Country,
        RateDifference: item?.difference || item?.RateDifference,
        ConvertedPriceRate: item?.editPrice || item?.convertedPrice,
        ChangedPrice: item?.editPrice || item?.convertedPrice,
        Symbol: item?.Symbol || item?.symbol,
      }))
    };

    let firstApiStatus = false;
    let resultapi = {};
    let resjson = {};

    try {
      const res = await fetch(`${process.env.REACT_APP_Baseurl}/admin/Livestream/PayPerViewPrice/StoreUpdate`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(dataToSubmit),
      });

      resjson = await res.json();

      if (resjson?.status === true) {
        firstApiStatus = true;
        resultapi = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
      // Optionally show a user-friendly error message
      alert('An error occurred while submitting the form. Please try again.');
    }

    if (firstApiStatus) {
      props?.setApiresponsealert(resultapi);
      props?.setShowalert(true);
      handleClose();
      setStep(1);
    } else {
      if (resjson?.status === false) {
        props?.setApiresponsealert(resjson);
        props?.setShowalert(true);
      }
    }
  };


  const handleInputChangeeditable = (index, fieldName, value) => {
    const updatedConvertedPrices = [...(modalType === '1' ? formDataglobalprice.convertedPrices : formDatacustomprice.convertedPrices)];
    updatedConvertedPrices[index][fieldName] = value;
    if (modalType === '1') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    } else {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        convertedPrices: updatedConvertedPrices
      }));
    }
  };

  const handleCustomPriceTypeChange = async (event) => {
    const selectedCountryCode = event.target.value;

    if (selectedCountryCode && selectedCountryCode !== allCurrencylistfromappichoosed?.code) {
      try {
        // Fetch conversion rates from API
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${selectedCountryCode}`);
        const rates = response.data.rates;

        // Convert and update prices based on the API data
        const convertedPrices = allCurrencylistfromappi?.map(item => {
          let country_name = item?.country;
          let Symbol = item?.Symbol || item?.symbol;
          let Code = item?.code;

          // Find matching country data
          const matchingCountry = allCurrencylistfromappi.find(currency => currency.code === item?.code);
          if (matchingCountry) {
            country_name = matchingCountry?.country;
            Symbol = matchingCountry?.Symbol || matchingCountry?.symbol;
          }

          return {
            Code: Code,
            Country: country_name,
            Symbol: Symbol,
            convertedPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            price: formDatacustomprice.price,
            difference: ((formDatacustomprice.price * (rates[item?.code] || 0)) - formDatacustomprice.price).toFixed(2),
            editPrice: (formDatacustomprice.price * (rates[item?.code] || 0)).toFixed(2),
            isEditable: true,
          };
        });

        setFormDatacustomprice(prevState => ({
          ...prevState,
          priceType: selectedCountryCode,
          convertedPrices: convertedPrices
        }));
      } catch (error) {
        console.error('Error fetching currency conversion rates:', error);
      }
    } else {
      // Set the existing data if allCurrencylistfromappichoosed?.code is selected
      const customPrices = allCurrencycustoms?.map(item => ({
        ...item,
        convertedPrice: item?.ChangedPrice || (formDatacustomprice.price * (item?.ConvertedPriceRate || 1)).toFixed(2),
        difference: item?.RateDifference || 0,
        isEditable: true
      }));

      setFormDatacustomprice(prevState => ({
        ...prevState,
        priceType: allCurrencylistfromappichoosed?.code,
        convertedPrices: customPrices
      }));
    }
  };

  const handlePriceChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === 'global') {
      setFormDataglobalprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (formType === 'custom') {
      setFormDatacustomprice(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };




  // {ppv price with global and customs} 
  // {ppv price with global and customs} 

  return (
    <div>
      <div className=" m-0 p-0">
        <div className="">
          <section className="container-fluid">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>{label_management?.livestream[57]?.title}</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.livestream[56]?.title}
                  </button>
                </div>
              </div>
            </div>

            <div>
              {showOverlay && <Response_Processing></Response_Processing>}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="card p-3">
                  <h5>{editname?.title}</h5>
                  <hr></hr>
                  <div className=" text-start">
                    <label className="m-0">
                      {label_management?.livestream[2]?.title}<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      {label_management?.livestream[2]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        value={editUser?.title}
                        onChange={handleInput}
                        className="rs-input form-control-lg"
                        placeholder="Title"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.livestream[3]?.title}
                      <span className="mandatory">*</span></label>
                    <p className="t-1">
                      {label_management?.livestream[3]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="slug"
                        id="slug"
                        onChange={handleInput}
                        value={editUser?.slug}
                        className="rs-input form-control-lg"
                        placeholder="slug"
                        ref={slugInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0">{label_management?.livestream[4]?.title}</label>
                    <p className="t-1">
                      {label_management?.livestream[4]?.sub_title}
                    </p>
                    <div className="mt-2">
                      <textarea
                        className="rs-input short-description"
                        id="description"
                        name="description"
                        placeholder="Description"
                        onChange={handleInput}
                        value={editUser?.description}
                      />
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.livestream[5]?.title}</label>
                      <p className="t-1">
                        {label_management?.livestream[5]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input"
                          value={editUser?.details}
                          onChange={(e) => {
                            handleChangeFirstdetails(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.livestream[6]?.title}</label>
                      <p className="t-1">
                        {label_management?.livestream[6]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          className="rs-input form-control-lg"
                          value={editUser?.duration}
                          maxLength="8"
                          placeholder="duration"
                          onChange={handleInputdata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0">{label_management?.livestream[7]?.title}</label>
                      <p className="t-1">
                        {label_management?.livestream[7]?.sub_title}
                      </p>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="date"
                          id="date"
                          value={editUser?.year || ''}
                          onChange={handleChangeyear}
                          className="rs-input form-control-lg"
                          placeholder="Year"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card p-3">
                    <h5>{label_management?.livestream[8]?.title}</h5>
                    <hr></hr>

                    <div className=" text-start">
                      <label className="m-0">{label_management?.livestream[9]?.title}</label>
                      <p className="t-1">{label_management?.livestream[9]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.rating}
                        >
                          <option value="">Select Rating</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    <div className="mt-2 text-start">
                      <label className="m-0">{label_management?.livestream[10]?.title}</label>
                      <p className="t-1">{label_management?.livestream[10]?.sub_title}</p>
                      <div className="mt-2">
                        <select
                          className="form-select"
                          id="age_restrict"
                          name="age_restrict"
                          onChange={handleInput}
                          value={editUser?.age_restrict}
                        >
                          <option value="">Choose an Age</option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.id}>{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.livestream[11]?.title}</label>
                      <p className="t-1">{label_management?.livestream[11]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className=""
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="m-0">
                        {label_management?.livestream[12]?.title}<span className="mandatory">*</span>
                      </label>
                      <p className="t-1">{label_management?.livestream[12]?.sub_title}</p>

                      <div className="mt-2">
                        <Select
                          options={optionscategories}
                          isMulti
                          className=""
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">
                        {label_management?.livestream[13]?.title}
                        {/* <span className="mandatory">*</span> */}
                      </label>
                      <p className="t-1">{label_management?.livestream[13]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className=""
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                        // ref={languageInputRef}
                        />
                      </div>
                      {/* <span className="errorred">
                        {!isValidationHiddenlanguages && (
                          <p>{validationMessagelanguages}</p>
                        )}
                      </span> */}
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.livestream[14]?.title}</label>
                      <p className="t-1">{label_management?.livestream[14]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={options}
                          isMulti
                          className=""
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0">{label_management?.livestream[15]?.title}</label>
                      <p className="t-1">{label_management?.livestream[15]?.sub_title}</p>
                      <div className="text-dark">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className=""
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.livestream[16]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.livestream[17]?.title}</label>
                    <p className="t-1">{label_management?.livestream[17]?.sub_title}</p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        name="website_page_title"
                        value={editUser?.website_page_title}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                      // placeholder="websitetitle"
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.livestream[18]?.title}</label>
                    <p className="t-1">{label_management?.livestream[18]?.sub_title}</p>
                    <div className=" mt-2">
                      <input
                        type="text"
                        name="website_URL"
                        value={editUser?.website_URL}
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                      // placeholder="websiteurl"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0">{label_management?.livestream[19]?.title}</label>
                    <p className="t-1">{label_management?.livestream[19]?.sub_title}</p>
                    <div className=" mt-2">
                      <textarea
                        type="text"
                        value={editUser?.Meta_description}
                        name="Meta_description"
                        className="rs-input form-control-lg"
                        onChange={handleInput}
                      // placeholder="metadescription"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.livestream[54]?.title}</h5>
                  <hr></hr>

                  <div className="col-sm-12">
                    <label className="m-0">{label_management?.livestream[55]?.title}</label>
                    <p className="p2">{label_management?.livestream[55]?.sub_title}</p>

                    <div className="mt-2">
                      <TagsInput
                        value={tags}
                        onChange={handleTagsChange}
                        inputValue={newTag}
                        onChangeInput={(tag) => setNewTag(tag)}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    {label_management?.livestream[56]?.title}
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                {editUser?.livestream_URL ? (
                  <div className="card p-3">
                    <h5>LiveStream {formattedDatatype}</h5>
                    <hr></hr>
                    <AdminCustomURLCopy link={editUser?.livestream_URL} fieldName={"Video URL"} className="copy-board-input" labelClassName="mt-md-2" />

                    <div className="text-start">
                      <ReactPlayer
                        url={editUser?.livestream_URL}
                        controls={true} // Enable player controls
                        width="100%"
                        height="230px"
                      />
                    </div>
                  </div>
                ) : null}

                <div className="card p-3">
                  <h5>{label_management?.livestream[20]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.livestream[21]?.title}
                      {compressResolutionFormat?.live === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.livestream[21]?.sub_title}
                    </p>
                    {/* <img
                      src={editUser?.Video_thumbnail}
                      width="150"
                      height="100"
                    /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Video_thumbnail}
                          alt="Image_Thumbnail"
                          className="thumbnails-images"
                        />
                      )}
                    </div>
                    <AdminCustomURLCopy link={editUser?.Video_thumbnail} fieldName={"Thumbnail image url"} className="copy-board-input" labelClassName="mt-md-2" />

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1,
                                  'file1'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file1 && <div className="errorred mt-1">{error?.file1}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0">{label_management?.livestream[22]?.title}
                      {compressResolutionFormat?.live === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.livestream[22]?.sub_title}
                    </p>
                    {/* <img
                      src={editUser?.Player_thumbnail}
                      width="150"
                      height="100"
                    /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Player_thumbnail}
                          alt="Image_Thumbnail"
                          className="thumbnails-images"
                        />
                      )}
                    </div>
                    <AdminCustomURLCopy link={editUser?.Player_thumbnail} fieldName={"Player image url"} className="copy-board-input" labelClassName="mt-md-2" />

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2,
                                  'file2'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file2 && <div className="errorred mt-1">{error?.file2}</div>}
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0"> {label_management?.livestream[23]?.title}
                      {compressResolutionFormat?.live === 1 && (
                        <span className="mandatory">*</span>
                      )}
                    </label>
                    <p className="t-1">
                      {label_management?.livestream[23]?.sub_title}
                    </p>
                    {/* <img
                      src={editUser?.Video_TV_Thumbnail}
                      width="150"
                      height="100"
                    /> */}

                    <div>
                      {loading ? (
                        <Response_Loading_processing></Response_Loading_processing>
                      ) : (
                        <img
                          src={editUser?.Video_TV_Thumbnail}
                          alt="Image_Thumbnail"
                          className="thumbnails-images"
                        />
                      )}
                    </div>
                    <AdminCustomURLCopy link={editUser?.Video_TV_Thumbnail} fieldName={"Tv image url"} className="copy-board-input" labelClassName="mt-md-2" />

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3,
                                  'file3'
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label>
                              <CameraRetroIcon />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL?.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {error?.file3 && <div className="errorred mt-1">{error?.file3}</div>}
                  </div>
                </div>

                <div className="card p-3">
                  <h5>{label_management?.livestream[24]?.title}</h5>
                  <hr></hr>

                  <div className=" text-start">
                    <label className="m-0">{label_management?.livestream[25]?.title}</label>
                    <p className="t-1">{label_management?.livestream[25]?.sub_title}</p>
                    <div className="mt-2">
                      <select
                        // onChange={(e) => setStorelive_source(e.target.value)}
                        onChange={handleInput}
                        name="url_type"
                        id="url_type"
                        value={editUser?.url_type}
                        className="form-select"
                        onClick={(event) => {
                          setalllivestream_source(event.target.value);
                        }}
                      >
                        <option value="0">Choose Live Source </option>
                        {livestream_source?.map((item, key) => (
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_stream_source()}</div>
                  </div>
                </div>

                <div className="card p-3">
                  {" "}
                  <h5>{label_management?.livestream[26]?.title}</h5>
                  <hr></hr>
                  <div className="row  text-start ">
                    <div className="col-sm-9">
                      <label className="m-0">{label_management?.livestream[27]?.title}</label>
                    </div>
                    <div className="col-sm-3 text-end">
                      <label className="switch">
                        <input
                          name="featured"
                          checked={editUser?.enable_restream === 1}
                          onChange={handleCheckbox}
                          type="checkbox"
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  {editUser?.enable_restream === 1 && (
                    <>
                      <div
                        className="accordion mt-3"
                        id="accordionSocialExample"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button collapsed p-3"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              <img
                                src={youtube}
                                className="accordionImage"
                                alt="Animated GIF"
                              />
                              <strong className="lh-1 ms-1">{label_management?.livestream[28]?.title}</strong>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse "
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionSocialExample"
                          >
                            <div className="accordion-body py-3 px-0">
                              <div className="row text-start m-0 p-0">
                                <div className="col-12 mb-2">
                                  <div className="">
                                    <label className="mb-1">
                                      {label_management?.livestream[29]?.title} <small>{label_management?.livestream[29]?.sub_title}</small>
                                    </label>

                                    <input
                                      type="text"
                                      className="rs-input form-control-lg"
                                      name="youtube_restream_url"
                                      id="youtube_restream_url"
                                      onChange={handleInput}
                                      value={editUser?.youtube_restream_url}
                                    // placeholder="youtube_restream_url"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 ">
                                  <div className="" id="">
                                    <div>
                                      <label className="mb-1">
                                        {label_management?.livestream[30]?.title}
                                      </label>
                                    </div>{" "}
                                    <input
                                      type="text"
                                      className="rs-input form-control-lg"
                                      name="youtube_streamkey"
                                      id="youtube_streamkey"
                                      onChange={handleInput}
                                      value={editUser?.youtube_streamkey}
                                    // placeholder="youtube_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed p-3"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              {" "}
                              <img
                                src={facebook}
                                className="accordionImage"
                                alt="Animated GIF"
                              />
                              <strong className="lh-1 ms-1"> {label_management?.livestream[31]?.title}</strong>
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionSocialExample"
                          >
                            <div className="accordion-body py-3 px-0">
                              <div className="row  text-start p-0 m-0">
                                <div className="col-12 mb-2 ">
                                  <div className="">
                                    <label className="mb-1">
                                      {label_management?.livestream[32]?.title}
                                      <small>{label_management?.livestream[32]?.sub_title}</small>
                                    </label>
                                    <input
                                      type="text"
                                      className="rs-input form-control-lg"
                                      name="fb_restream_url"
                                      id="fb_restream_url"
                                      onChange={handleInput}
                                      value={editUser?.fb_restream_url}
                                    // placeholder="fb_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-12 ">
                                  <div className="" id="">
                                    <div>
                                      <label className="mb-1">
                                        {label_management?.livestream[32]?.title} Key
                                      </label>
                                    </div>{" "}
                                    <input
                                      type="text"
                                      className="rs-input form-control-lg mt-2"
                                      name="fb_streamkey"
                                      id="fb_streamkey"
                                      onChange={handleInput}
                                      value={editUser?.fb_streamkey}
                                    // placeholder="fb_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed p-3"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <img
                                src={twitter}
                                className="accordionImage"
                                alt="Animated GIF"
                              />
                              <strong className="lh-1 ms-1"> {label_management?.livestream[33]?.title}</strong>
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionSocialExample"
                          >
                            <div className="accordion-body py-3 px-0">
                              <div className="row  text-start m-0 p-0">
                                <div className="col-12 mb-2">
                                  <div className="">
                                    <label className="mb-1">
                                      {label_management?.livestream[34]?.title} <small>{label_management?.livestream[34]?.sub_title}</small>
                                    </label>
                                    <input
                                      type="text"
                                      className="rs-input form-control-lg"
                                      name="twitter_restream_url"
                                      id="twitter_restream_url"
                                      onChange={handleInput}
                                      value={editUser?.twitter_restream_url}
                                    // placeholder="twitter_restream_url"
                                    />
                                  </div>
                                </div>

                                <div className="col-12">
                                  <div className="mt-2">
                                    <label className="mb-1">
                                      {label_management?.livestream[35]?.title}
                                    </label>

                                    <input
                                      type="text"
                                      className="rs-input form-control-lg"
                                      name="twitter_streamkey"
                                      id="twitter_streamkey"
                                      onChange={handleInput}
                                      value={editUser?.twitter_streamkey}
                                    // placeholder="twitter_streamkey"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="card p-3">
                    <h5>{label_management?.livestream[36]?.title} </h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="mb-2">{label_management?.livestream[37]?.title} </label>
                      <div>
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          value="publish_now"
                          checked={publishType === "publish_now"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_now" className="mt-2 ms-1">
                          {label_management?.livestream[38]?.title}
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="publish_later"
                          name="publish_type"
                          value="publish_later"
                          checked={publishType === "publish_later"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_later" className="mt-2 ms-1">
                          {label_management?.livestream[39]?.title}
                        </label>
                        {publishType === "publish_later" && (
                          <div className="mb-3">
                            <label className="mt-2">{label_management?.livestream[40]?.title}</label>
                            <input
                              type="datetime-local"
                              className="form-control"
                              id="publish_time"
                              name="publish_time"
                              value={publishTime}
                              onChange={handlePublishTimeChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-sm-12">
                      <label className="mb-2">Publish Type</label>
                      <div
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                      >
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                          value="publish_now"
                          checked="checked"
                        />{" "}
                        <span
                          className="mt-2"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        >
                          Publish Now
                        </span>
                      </div>
                      <div
                        onClick={(event) => {
                          setEnable_restreampublic(event.target.value);
                        }}
                      >
                        <input
                          type="radio"
                          id="publish_later"
                          value="publish_later"
                          name="publish_type"
                          className="mt-2"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        />{" "}
                        <span
                          value="publish_later"
                          name="publish_type"
                          onClick={(event) => {
                            setEnable_restreampublic(event.target.value);
                          }}
                          onChange={(e) => setPublish_type(e.target.value)}
                        >
                          Publish Later
                        </span>{" "}
                      </div>
                    </div>

                    <div className="col-sm-12" id="publishlater">
                      <div
                        className="mt-2"
                        onClick={(event) => {
                          setEnablestreamvisiblepublic(event.target.value);
                        }}
                      >
                        {proceednextpublic()}
                      </div>
                    </div> */}
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.livestream[41]?.title} </h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <label className="m-0 mandatorySimple">{label_management?.livestream[42]?.title} </label>
                      <p className="p1">
                        {label_management?.livestream[42]?.sub_title}
                      </p>
                      <select
                        // onChange={(e) => setStoreliveuseraccess(e.target.value)}
                        onChange={handleInput}
                        name="access"
                        id="access"
                        value={editUser?.access}
                        className="form-select"
                        ref={accessInputRef}
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        <option value="">Choose an Option</option>
                        {user_access?.map((item, key) => (
                          <option value={item?.value}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>


                    <span className="errorred mt-2 d-block">
                      {!isValidationHiddenAccess && (
                        <p>{validationMessageAccess}</p>
                      )}
                    </span>

                  </div>




                  <div className="card p-3">
                    <h5>{label_management?.livestream[43]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12">
                      <div className="col-sm-12 row mt-2">
                        <div className="col-9 col-sm-9 col-lg-9">
                          <p className="p2">{label_management?.livestream[44]?.title}</p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3">
                          <label className="switch">
                            <input
                              name="free_duration_status"
                              id="free_duration_status"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.free_duration_status === 1
                                  ? true
                                  : false
                              }
                              checked={
                                editUser?.free_duration_status === 1
                                  ? true
                                  : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              id="free_duration_status"
                              name="free_duration_status"
                              onChange={handleInputenable}
                              value={
                                editUser?.free_duration_status === 1 ? "1" : "0"
                              }
                            ></span>
                          </label>
                        </div>
                      </div>

                      <div className="row mt-2 text-start">
                        <div className="col-12">
                          <label className="m-0">{label_management?.livestream[45]?.title}</label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="free_duration_time"
                              className="rs-input form-control-lg"
                              value={editUser?.free_duration_time}
                              maxLength="8"
                              placeholder="HH:MM:SS"
                              onChange={handleInputdata}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.livestream[46]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.livestream[47]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              editUser?.featured === 1 ? true : false
                            }
                            checked={editUser?.featured === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            value={editUser?.featured === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.livestream[48]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.active === 1 ? true : false
                            }
                            checked={editUser?.active === 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            value={editUser?.active === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">
                          {label_management?.livestream[49]?.title}
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="banner"
                            id="banner"
                            onChange={handleInputenable}
                            className="r-input"
                            defaultChecked={
                              editUser?.banner === 1 ? true : false
                            }
                            checked={editUser?.banner === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            id="banner"
                            onChange={handleInputenable}
                            value={editUser?.banner === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 row mt-2">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">
                          {label_management?.livestream[50]?.title}
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_chats"
                            id="enable_chats"
                            onChange={handleInputenable}
                            className="r-input"
                            defaultChecked={
                              editUser?.enable_chats === 1 ? true : false
                            }
                            checked={editUser?.enable_chats === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="enable_chats"
                            id="enable_chats"
                            onChange={handleInputenable}
                            value={editUser?.enable_chats === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="card p-3">
                    <h5>{label_management?.livestream[51]?.title}</h5>
                    <hr></hr>

                    <div className="col-sm-12 row ">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2">{label_management?.livestream[52]?.title}</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="enable_Tips"
                            onChange={handleInputenable}
                            className="r-input"
                            defaultChecked={
                              editUser?.enable_Tips === 1 ? true : false
                            }
                            checked={editUser?.enable_Tips === 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="enable_Tips"
                            onChange={handleInputenable}
                            value={editUser?.enable_Tips === 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0">{label_management?.livestream[53]?.title}</label>

                        <div className="mt-2">
                          <input
                            type="number"
                            name="donations_label"
                            id="donations_label"
                            className="rs-input form-control-lg"
                            value={editUser?.donations_label}
                            placeholder=""
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="card p-3">
                    <h5>Choose Ads Settings</h5>
                    <hr></hr>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Ads Position</label>

                      <div className=" mt-2">
                        <Select
                          options={adsPositions}
                          className="rs-input"
                          onChange={(e) => handleAdsPositionChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="m-0">Choose Advertisement</label>

                      <div className=" mt-2">
                        <Select
                          options={secondSelectOptions}
                          className="rs-input"
                          onChange={(e) => setSecondSelectOptions(e)}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>

          {/* <section className="container-fluid">
            <div className="col-lg-12">
              <div className="card text-center">
                <div className="card-body">
                  <div className="container-fluid p-0">
                    <div className="p-3">
                      <form onSubmit={handleUpdate}>
                        <div>
                          <h4 className="">Add New LiveStream </h4>
                          <hr />

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Title</label>
                              <p className="">
                                Add the Live stream title in the textbox below:
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  id="title"
                                  value={editUser?.title}
                                  onChange={handleInput}
                                  placeholder="Title"
                                />
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">Slug</label>
                              <p className="">
                                Add the Live stream slug in the textbox below:
                              </p>
                              <div>
                                <input
                                  type="text"
                                  name="slug"
                                  className="form-control"
                                  value={editUser?.slug}
                                  onChange={handleInput}
                                  placeholder="slug"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start align-items-center">
                            <div className="col-md-6">
                              <label className="m-0">Live Stream Video</label>
                              <p className="p1">
                                Select the Live stream image ( 9:16 Ratio or
                                1080X1920px ):
                              </p>
                              <div>
                                <img
                                  src={editUser?.Video_thumbnail}
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Live_stream_video"
                                  name="image"
                                  id="image"
                                  onChange={(e) => setImage(e.target.files[0])}
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <label className="m-0">
                                  Player Image Cover
                                </label>
                                <p className="">
                                  Select the Live stream image( 16:9 Ratio or
                                  1280X720px ):
                                </p>
                                <div>
                                  <img
                                    src={editUser?.Player_thumbnail}
                                    width={150}
                                    height={150}
                                  />

                                  <input
                                    type="file"
                                    className="form-control"
                                    placeholder="player_image"
                                    name="player_image"
                                    id="player_image"
                                    onChange={(e) =>
                                      setPlayer_image(e.target.files[0])
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Live Stream Source</label>
                              <p className="p1">
                                Select the Live Stream Source :
                              </p>
                              <div className="">
                                <div>
                                  <select
                                    className="form-select url_type"
                                    onChange={handleInput}
                                  >
                                    <option
                                      name="url_type"
                                      value={editUser?.url_type}
                                    >
                                      Choose URL Format
                                    </option>
                                    <option
                                      name="mp4_url"
                                      value={editUser?.mp4_url}
                                    >
                                      mp4
                                    </option>
                                    <option value="Embed_url">Embed_url</option>
                                    <option value="Mp3">Mp3</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">TV Image Cover</label>
                              <div className="new-video-upload" id="">
                                <p className="p1">
                                  Select The Live Stream TV Image (1920 X 1080
                                  Or 16:9 Ratio) :
                                </p>
                                <img
                                  src={editUser?.Video_TV_Thumbnail}
                                  width={150}
                                  height={150}
                                />

                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Tv_live_image"
                                  name="Tv_live_image"
                                  id="Tv_live_image"
                                  onChange={(e) =>
                                    setTv_live_image(e.target.files[0])
                                  }
                                />
                              </div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Enable ReStream</label>
                              
                              <div>
                                <select className="form-select url_type mt-3">
                                  <option
                                    name="enable_restream"
                                    value={editUser?.enable_restream}
                                    onChange={handleInput}
                                  >
                                    {editUser?.enable_restream}
                                  </option>
                                  <option value="1">Yes</option>
                                  <option value="0">No</option>
                                </select>
                              </div>
                              <div></div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-12">
                              <label className="m-0">Short Description</label>
                              <p className="">
                                Add a short description of the Livestream below:
                              </p>
                              <div className="">
                                <textarea
                                  className="form-control"
                                  value={editUser?.description}
                                  name="description"
                                  onChange={handleInput}
                                  placeholder="Description"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Search Tags</label>
                              <div className="">
                                <input
                                  className="form-control me-2"
                                  type="search"
                                  placeholder="search_tags"
                                  aria-label="Search"
                                  value={editUser?.search_tags}
                                  onChange={handleInput}
                                  name="search_tags"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Artists</label>
                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={artist}
                                  showCheckbox
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-12">
                              <label className="m-0">
                                Live Stream Details, Links, and Info
                              </label>
                             

                              <div className="panel-body mt-3">
                                <JoditEditor
                                  value={editUser?.details}
                                  onChange={(e) => {
                                    handleChangeFirst(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Category</label>
                              <p className="p1">
                                Select A Live Stream Category Below:
                              </p>

                              <div className="">
                                <Select
                                  isMulti
                                  options={allOptions}
                                  value={selectedOptions}
                                  onChange={(selected) =>
                                    setSelectedOptions(selected)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Language</label>
                              <p className="p1">
                                Select A Live Stream Language Below:
                              </p>
                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={language}
                                  showCheckbox
                                  name="language"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Age Restriction</label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={cate}
                                  showCheckbox
                                ></Multiselect>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">Block Country</label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={language}
                                  showCheckbox
                                  name="language"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row text-start mt-4">
                            <div className="col-sm-6">
                              <label className="m-0">Live Stream Ratings</label>
                              <p className="p1">
                                Live Stream Ratings 10 Out Of 10{" "}
                              </p>
                              <div className="form-control">
                                <select
                                  className="form-select"
                                  aria-label="Default select example"
                                >
                                  <option
                                    value={editUser?.rating}
                                    onChange={handleInput}
                                    name="rating"
                                  ></option>
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-sm-6">
                              <label className="m-0">Live Stream Year</label>
                              <p className="p1">Live Stream Released Year</p>
                              <div className="">
                                <input
                                  type="text"
                                  name="year"
                                  className="form-control"
                                  value={editUser?.year}
                                  onChange={handleInput}
                                  placeholder="Set Year"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Choose Ads Position</label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={cate}
                                  showCheckbox
                                ></Multiselect>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">
                                Choose Advertisement
                              </label>

                              <div className="">
                                <Multiselect
                                  isObject={false}
                                  options={language}
                                  showCheckbox
                                  name="language"
                                ></Multiselect>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-6">
                              <label className="m-0">Duration</label>
                              <p className="p1">
                                Enter The Live Stream Duration In (HH : MM : SS)
                              </p>
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={editUser?.duration}
                                  onChange={handleInput}
                                  name="duration"
                                  placeholder="duration"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <label className="m-0">User Access</label>
                              <p className="p1">
                                Who Is Allowed To View This Live Stream ?
                              </p>
                              <select className="form-control">
                                <option
                                  value={editUser?.access}
                                  onChange={handleInput}
                                  name="access"
                                ></option>
                              </select>
                            </div>
                          </div>

                          <div className="row mt-3 text-start">
                            <div className="col-sm-4">
                              <label className="m-0">Publish Type</label>
                              <div className="p2">
                                <div>
                                  <input
                                    type="radio"
                                    name="type"
                                    className="form-group"
                                    value={editUser?.publish_type}
                                    onChange={handleInput}
                                    placeholder="publish_type"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-sm-5" id="publishlater">
                              <label className="m-0">Status Settings</label>
                              <div>
                                <p className="p2">
                                  Is this Live stream Featured:
                                  <label className="switch">
                                    <input
                                      name="featured"
                                      className="featured form-group"
                                      onChange={handleInput}
                                      id="featured"
                                      type="checkbox"
                                      value={editUser?.featured}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>
                              <div>
                                <p className="p2">
                                  Is this Live stream Active:
                                  <label className="switch">
                                    <input
                                      name="active"
                                      className="active form-group"
                                      onChange={handleInput}
                                      id="active"
                                      type="checkbox"
                                      value={editUser?.active}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>

                              <div>
                                <p className="p2">
                                  Is this Live stream display in Banner:
                                  <label className="switch">
                                    <input
                                      name="banner"
                                      className="banner form-group"
                                      onChange={handleInput}
                                      id="banner"
                                      type="checkbox"
                                      value={editUser?.banner}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="text-end">
                            <button className="btn btn-primary">Update</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </div>
  );
}

export default EditAddNewLiveStream;
