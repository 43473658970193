import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Response_Processing from "../../../../src/layout/Others/Response_Processing";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";


function CommentSetting(props) {
  const [order, setOrder] = useState({});
  const [editUser, setEditUser] = useState({
    id: "",
    videos: "",
    livestream: "",
    episode: "",
    audios: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const access_token = localStorage.getItem("access_token");

  // console.log(access_token)

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // console.log(headers)

  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/admin/Comment-section/index`, {
        headers: headers,
      })
      .then((response) => {
        // console.log("api checks", response.data.comment_section);
        var result = response?.data?.comment_section;
        setOrder(result);
        setEditUser(result);
        // console.log('result', result)
        // console.log('orders', order);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInput = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
    if (e.target.checked === true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const videos = Number.isNaN(parseInt(editUser?.videos, 10)) ? 0 : parseInt(editUser?.videos, 10);
    const livestream = Number.isNaN(parseInt(editUser?.livestream, 10)) ? 0 : parseInt(editUser?.livestream, 10);
    const episode = Number.isNaN(parseInt(editUser?.episode, 10)) ? 0 : parseInt(editUser?.episode, 10);
    const audios = Number.isNaN(parseInt(editUser?.audios, 10)) ? 0 : parseInt(editUser?.audios, 10);

    const editInputvalue = {
      id: editUser?.id,
      videos: videos,
      livestream: livestream,
      episode: episode,
      audios: audios,
    };

    setProcessing(true);
    setShowOverlay(true);

    let res = await fetch(
      `${process.env.REACT_APP_Baseurl}/admin/Comment-section/update`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(editInputvalue),
      }
    );
    let resjson = await res.json();

    if (resjson.status === true) {
      var resultapi = resjson;

      props.setApiresponsealert(resultapi);
      props.setShowalert(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi && resultapi.status === false) {
        var resultError = resultapi;
        props.setApiresponsealert(resultError);
        props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };








  const sourceTypeMap = {
    "1": "Video",
    "2": "Livestream",
    "3": "Series",
    "4": "Episode",
    "5": "Audios",
    "6": "Season",
  };


  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [withOutRefresh, setWithoutRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedType, setSelectedType] = useState("");

  // Fetch Data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/comments`,
        { headers: headers }
      );
      const result = response?.data?.comments;

      // Flattening the nested response
      const flattenedData = [];
      Object.keys(result).forEach((key) => {
        flattenedData.push(...result[key]);
      });

      setApiData(flattenedData);
      setFilteredData(flattenedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  // Toggle Action after confirmation
  const action = async (row) => {
    try {
      const newStatus = row.approved === 1 ? 0 : 1;
      const formData = new FormData();
      formData.append("comment_id", row.id);
      formData.append("approved", newStatus);

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/comments-status-update`,
        formData,
        { headers: headers }
      );

      const result = response?.data;
      if (result?.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Error updating status.";
      toast.error(errorMessage);
    }
  };

  // Handle Toggle Click
  const handleToggleClick = (row) => {
    setSelectedRow(row); // Store selected row data
    setShowModal(true); // Open confirmation modal
  };

  // Handle Confirmation
  const handleConfirm = () => {
    setShowModal(false);
    if (selectedRow) {
      action(selectedRow); // Trigger API call
    }
  };

  // Handle Cancel
  const handleCancel = () => {
    setSelectedRow(null);
    setShowModal(false);
  };
  // Handle Search
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  // Handle Commenter Type Filter
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    let updatedData = apiData;

    // Filter by search text
    if (searchText) {
      updatedData = updatedData.filter(
        (item) =>
          item.user_name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.comment.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // Filter by Commenter Type
    if (selectedType) {
      updatedData = updatedData.filter(
        (item) => item.source_type === selectedType
      );
    }

    setFilteredData(updatedData);
  }, [searchText, selectedType, apiData]);

  // Table Columns
  const columns = [
    {
      dataField: "user_name",
      text: "Name",
      formatter: (cell, row) => (
        <span>
          {`${row.user_name ? row.user_name.slice(0, 15) : "Unknown"}...`}
        </span>
      ),
      sort: true,
    },
    {
      dataField: "comment",
      text: "Comment",
      formatter: (cell, row) => (
        <div>
          <span>{`${row.comment}`}</span>
        </div>
      ),
    },
    {
      dataField: "source_type",
      text: "Commenter Type",
      formatter: (cell, row) => (
        <div
          className={`d-inline-flex justify-content-center px-3 py-1 text-center ${row.source_type === "1"
            ? "Video"
            : row.source_type === "2"
              ? "Livestream"
              : row.source_type === "3"
                ? "Series"
                : row.source_type === "4"
                  ? "Episode"
                  : row.source_type === "5"
                    ? "Audios"
                    : row.source_type === "6"
                      ? "Season"
                      : ""
            }`}
        >
          {sourceTypeMap[row.source_type] || "Unknown"}
        </div>
      ),
    },
    {
      dataField: "Action",
      text: "Action",
      formatter: (cell, row) => (
        <div>
          <label className="switch">
            <input
              name="approved"
              id="approved"
              className={row.approved === 0 ? "Disapprove" : "Approve"}
              checked={row.approved === 1}
              onChange={() => handleToggleClick(row)} // Open confirmation modal
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
      ),
    },
  ];

  // Fetch data on component load and when withOutRefresh changes
  useEffect(() => {
    fetchData();
  }, [withOutRefresh]);



  return (
    <>
      <div className=" m-0 p-0">
        <div className="">
          <section className="section container-fluid pt-3">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3>Comment Section</h3>
              </div>

              <div className="col-6 col-sm-6 col-lg-6 text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Save Comment
                </button>
              </div>
            </div>

            <div className="iq-card ">

              <div>
                {showOverlay && <Response_Processing></Response_Processing>}
              </div>

              <div className="col-lg-12">
                <div className="">
                  <Table>
                    <thead>
                      <tr className="title">
                        <th>COMMENT NAME</th>
                        <th>STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> Enable Comment Section for Videos </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="videos"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.videos === 1 ? true : false
                                }
                                checked={editUser?.videos === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="videos"
                                onChange={handleInput}
                                value={editUser?.videos === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Enable Comment Section for LiveStream </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="livestream"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.livestream === 1 ? true : false
                                }
                                checked={
                                  editUser?.livestream === 1 ? true : false
                                }
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="livestream"
                                onChange={handleInput}
                                value={editUser?.livestream === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td>Enable Comment Section for Episode</td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="episode"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.episode === 1 ? true : false
                                }
                                checked={editUser?.episode === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="episode"
                                onChange={handleInput}
                                value={editUser?.episode === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr>
                        <td> Enable Comment Section for Audios </td>
                        <td>
                          <div className="mt-1">
                            <label className="switch">
                              <input
                                name="audios"
                                onChange={handleInput}
                                defaultChecked={
                                  editUser?.audios === 1 ? true : false
                                }
                                checked={editUser?.audios === 1 ? true : false}
                                type="checkbox"
                              />
                              <span
                                className="slider round"
                                name="audios"
                                onChange={handleInput}
                                value={editUser?.audios === 1 ? "1" : "0"}
                              ></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>




      <div className="container-fluid mt-5">
        <h2>All Comments</h2>

        <div className="card">
          <div className="d-flex justify-content-between p-3">

            {/* Search and Filter */}
            <div className="d-flex mb-3">
              <input
                type="text"
                className="form-control mr-2"
                placeholder="Search by name or comment"
                value={searchText}
                onChange={handleSearch}
              />

            </div>

            <div className="mb-3">
              <CSVLink
                data={filteredData}
                filename="comments_export.csv"
                className="btn btn-primary"
                target="_blank"
              >
                Export to CSV
              </CSVLink>
            </div>

          </div>

          <div className="table-responsive">
            <BootstrapTable
              keyField="id"
              data={filteredData}
              columns={columns}
              pagination={paginationFactory()}
              noDataIndication="No records found"
              hover
              striped
              bordered={false}
            />
          </div>
        </div>
        {/* Confirmation Modal */}
        <Modal show={showModal} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to change the status?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              No
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default CommentSetting;
